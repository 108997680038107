import React from "react";
import { Row, Container, Breadcrumb } from "react-bootstrap";
import { navigate, Link } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { isUserSignedIn } from "../../components/accountManagement";
import { SideBarOptions, adminMenuLinks, TopMenuOptions } from "../../components/genericMenu";
import { CrudTable } from "../../components/crudTable";
import { doesUserHaveRole } from "../../components/accountManagement";

const uri = "/admin/recipes/";

const AdminRecipesPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);

  const DataRow = ({ data }) => {
    return (
      <>
        <td>{data.id}</td>
        <td><Link to={"/recipes/view/" + data.recipe_for_id}>{data.recipe_name}</Link></td>
        <td>{data.recipe_for_id}</td>
        <td dangerouslySetInnerHTML={{ __html: data.excerpt }} className="admin-recipes-excerpt-col"></td>
        <td>{data.rating}</td>
        <td>{data.rating_count}</td>
        <td>{data.comment_count}</td>
      </>
    );
  };

  return (
    <Layout pageInfo={{ pageName: "admin-recipes" }} sideBarOptions={SideBarOptions(adminMenuLinks, uri, "Admin")}>
      <Seo title="Admin Recipes" keywords={[`reviews`]} description="Admins Recipes" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={adminMenuLinks} currentLink={uri} menuTitle="Admin" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>Recipes</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
      </Container>

      {isUserSignedIn(userData) && doesUserHaveRole(userData, "RECIPE_ADMIN") ? (
        <CrudTable
          url="/recipes"
          urlParams=""
          customFilters={[{ name: "search_text", param_name: "search_text", default: "" }]}
          colHeaders={["Id", "Recipe Name", "Url", "Excerpt", "Rating", "Rating Count", "Comment Count"]}
          rowComponent={(data) => {
            return <DataRow data={data} />;
          }}
        />
      ) : (
        <>Not logged in {navigate("/account/signin/")}</>
      )}
    </Layout>
  );
};

export default AdminRecipesPage;
